import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import getMoneda from '@constants/monedas';
import Tabla from '../../Tabla';
import { ITablasGeneralesProps } from './types';
import { ReactComponent as Excel2021Icon } from '@assets/icons/Excel2021.svg';

const TablasGenerales = (props: ITablasGeneralesProps) => {
  const {
    tablaCardiovascular,
    tablaAAA,
    tablaCancerPulmon,
    tablaCancerCervical,
    tablaHabitosRiesgo,
    tablaHacinamiento,
    tablaAudiometrias,
    tablaEMP,
    tablaReporteGeneral,
    tablaRiesgoOperativo,
    tablaGradoSalud,
    selectorTabla,
    handleChangeHabito,
    formatTabla,
  } = props;
  const { t } = useTranslation();
  const { pais } = useSelector((state: RootState) => state.Me);

  const getTituloCantidad = (titulo: string) => (
    <div>
      {t(titulo)}
      <div className="m-0">
        <span className="font-bold">{t('cantidad')}</span>
        <span className="font-normal"> (%)</span>
      </div>
    </div>
  );
  const getTituloCantidadGrado = (grado: number) => (
    <div>
      {`${t('grado')} ${grado}`}
      <div className="m-0 flex flex-col">
        <span className="font-normal">{`${t('mujer')} %`}</span>
        <span className="font-normal">{`${t('hombre')} %`}</span>
      </div>
    </div>
  );

  /* COLUMNAS */
  const columnasEstandar = [
    { Header: getTituloCantidad('mujer'), accessor: 'mujeres' },
    { Header: getTituloCantidad('hombre'), accessor: 'hombres' },
    { Header: t('business_total_alarma'), accessor: 'totalAlarma' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
  ];
  const columnasEstandarExt = [
    { Header: getTituloCantidad('mujer'), accessor: 'mujeres' },
    { Header: getTituloCantidad('hombre'), accessor: 'hombres' },
    { Header: t('business_total_alarma'), accessor: 'totalAlarma' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
    { Header: t('sin_datos'), accessor: 'sin_datos' },
  ];
  const columnasSinAlarma = [
    { Header: getTituloCantidad('mujer'), accessor: 'mujeres' },
    { Header: getTituloCantidad('hombre'), accessor: 'hombres' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
  ];
  const columnasExamenAudiometria = [
    { Header: getTituloCantidad('estudio_expirado'), accessor: 'expirado' },
    { Header: getTituloCantidad('estudio_por_expirar'), accessor: 'porExpirar' },
    { Header: t('business_total_alarma'), accessor: 'totalAlarma' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
    // { Header: t('sin_datos'), accessor: 'sin_datos' },
  ];
  const columnasExamenEMP = [
    { Header: getTituloCantidad('estudio_expirado'), accessor: 'expirado' },
    { Header: getTituloCantidad('estudio_por_expirar'), accessor: 'porExpirar' },
    { Header: t('business_total_alarma'), accessor: 'totalAlarma' },
    { Header: t('sin_datos'), accessor: 'sin_datos' },
  ];
  const columnasHabitosRiesgo = [
    { Header: t('business_habitos_riesgo'), accessor: 'titulo' },
    { Header: getTituloCantidad('mujer'), accessor: 'mujeres' },
    { Header: getTituloCantidad('hombre'), accessor: 'hombres' },
    { Header: t('business_total_habito_alarma'), accessor: 'totalAlarma' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
    { Header: t('ex_consumidor'), accessor: 'exConsumidor' },
    { Header: t('observaciones'), accessor: 'comentarios' },
    {
      Header: `${t('business_perdida_anual_habito')} (${getMoneda(pais).simbolo})`,
      accessor: 'perdida',
    },
    { Header: '', accessor: 'verDetalle' },
  ];
  const columnasRiesgOperativo = [
    { Header: t('item_salud'), accessor: 'diagnostico' },
    { Header: getTituloCantidad('riesgo_operativo'), accessor: 'riesgoOperativo' },
    { Header: getTituloCantidad('mujer'), accessor: 'mujeres' },
    { Header: getTituloCantidad('hombre'), accessor: 'hombres' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
  ];
  const columnasGradosSalud = [
    { Header: t('item_salud'), accessor: 'diagnostico' },
    { Header: getTituloCantidadGrado(4), accessor: 'grado4' },
    { Header: getTituloCantidadGrado(3), accessor: 'grado3' },
    { Header: getTituloCantidadGrado(2), accessor: 'grado2' },
    { Header: getTituloCantidadGrado(1), accessor: 'grado1' },
    { Header: getTituloCantidadGrado(0), accessor: 'grado0' },
    { Header: t('business_total_datos'), accessor: 'totalDatos' },
  ];

  /* OBTENER LAS TABLAS GENERALES */
  const getTabla = () => {
    switch (selectorTabla) {
      case 'riesgo_cardiovascular':
        return (
          <Tabla
            columns={columnasEstandar}
            centered
            data={formatTabla(tablaCardiovascular)}
            sort={false}
          />
        );
      case 'aaa':
        return (
          <Tabla columns={columnasEstandar} centered data={formatTabla(tablaAAA)} sort={false} />
        );
      case 'cancer_pulmon':
        return (
          <Tabla
            columns={columnasEstandar}
            centered
            data={formatTabla(tablaCancerPulmon)}
            sort={false}
          />
        );
      case 'cancer_cervical':
        return (
          <Tabla
            columns={columnasEstandar}
            centered
            data={formatTabla(tablaCancerCervical)}
            sort={false}
          />
        );
      case 'audiometria':
        return (
          <Tabla
            columns={columnasExamenAudiometria}
            centered
            data={formatTabla(tablaAudiometrias, ['expirado', 'porExpirar'])}
            sort={false}
          />
        );
      case 'emp':
        return (
          <Tabla
            columns={columnasExamenEMP}
            centered
            data={formatTabla(tablaEMP, ['expirado', 'porExpirar'], false)}
            sort={false}
          />
        );
      case 'habitos':
        return (
          <Tabla
            columns={columnasHabitosRiesgo}
            centered
            firstColumnIsTitle
            data={formatTabla(tablaHabitosRiesgo)}
            verDetalle={tablaHabitosRiesgo.map((habito) => habito.totalAlarma > 0)}
            callBackVerDetalle={(tipo: string) => handleChangeHabito(tipo)}
          />
        );
      case 'reporte_general':
        return (
          <Tabla
            columns={columnasSinAlarma}
            centered
            data={formatTabla(tablaReporteGeneral)}
            sort={false}
          />
        );
      case 'riesgos_operativos':
        return (
          <div>
            <div className="flex flex-row items-center justify-end">
              <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
              <IconButton aria-label="edit" onClick={() => {}} color="primary">
                <Excel2021Icon />
              </IconButton>
            </div>
            <Tabla
              columns={columnasRiesgOperativo}
              centered
              firstColumnIsTitle
              data={formatTabla(
                tablaRiesgoOperativo.sort((a, b) =>
                  a.riesgoOperativo > b.riesgoOperativo ? -1 : 1,
                ),
                ['riesgoOperativo', 'mujeres', 'hombres'],
              )}
            />
          </div>
        );
      case 'grados_salud':
        return (
          <div>
            <div className="flex flex-row items-center justify-end">
              <h4 className="text-gray-500 font-normal">{t('business_exportar_excel')}</h4>
              <IconButton aria-label="edit" onClick={() => {}} color="primary">
                <Excel2021Icon />
              </IconButton>
            </div>
            <Tabla
              columns={columnasGradosSalud}
              centered
              firstColumnIsTitle
              data={formatTabla(
                tablaGradoSalud,
                ['grado0', 'grado1', 'grado2', 'grado3', 'grado4'],
                true,
                true,
              )}
            />
          </div>
        );
      case 'otros_riesgos':
        return (
          <Tabla
            columns={columnasEstandarExt}
            centered
            data={formatTabla(tablaHacinamiento)}
            sort={false}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {['habitos', 'riesgos_operativos', 'grados_salud'].includes(selectorTabla) ? (
        getTabla()
      ) : (
        <div className="grid grid-cols-8 pt-4 mb-2">
          <div className="col-span-2" />
          <div className="col-span-4">{getTabla()}</div>
        </div>
      )}
    </>
  );
};

export default TablasGenerales;
