import { useTranslation } from 'react-i18next';
import DoughnutChart from '@components/Graficos/Doughnut';
import BarChart from '@components/Graficos/Bar';
import {
  IObjGraphCardiovascularProps,
  IObjTableHabitosProps,
  IObjTableExamenProps,
} from '../../types';
import { IGraficosProps } from './types';

const Graficos = (props: IGraficosProps) => {
  const {
    selectorTabla,
    tablaHabitosRiesgo,
    tablaHacinamiento,
    tablaAudiometrias,
    tablaEMP,
    graficoCardiovascular,
    tablaReporteGeneral,
    tablaRiesgoOperativo,
    tablaGradoSalud,
    graficosRiesgoOperativo,
    graficosGradoSalud,
    setGraphType,
    handleChangeHabito,
  } = props;
  const { t } = useTranslation();

  /* PALETA DE COLORES */
  const palette = {
    mujer: '#FF748A',
    hombre: '#4DA2EB',
    sin_datos: '#ADADAD',
    general: 'green',
    nulo: 'lightgreen',
    bajo: 'yellow',
    intermedio: 'orange',
    alto: 'red',
    muyAlto: '#BD0404',
    // nom_035_nulo: '#00B0F0',
    // nom_035_bajo: '#00B050',
    // nom_035_intermedio: '#FFFF00',
    // nom_035_alto: '#FFC000',
    // nom_035_muyAlto: '#FF0000',
  };

  /* OBTENER GRÁFICO DE EXÁMENES (AUDIOMETRÍA Y EMP) */
  const getGraficoExamen = (table: IObjTableExamenProps, id: string) => (
    <DoughnutChart
      id={id}
      etiquetas={[t('estudio_expirado'), t('estudio_por_expirar'), t('sin_datos')]}
      colores={[palette.muyAlto, palette.bajo, palette.sin_datos]}
      datos={[[table.expirado, table.porExpirar, table.sin_datos]]}
      totales={[table.totalDatos]}
      valorCentro={[table.expirado]}
      etiquetaCentro={t('estudio_expirado')}
    />
  );

  /* CONSTRUCTOR DE GRÁFICOS */
  const construirGraficos = () => {
    switch (selectorTabla) {
      case 'riesgo_cardiovascular':
        return (
          <DoughnutChart
            id="riesgo_cardiovascular"
            tipos={['mujer', 'hombre', 'total']}
            etiquetas={[
              t('riesgo_muy_alto'),
              t('riesgo_alto'),
              t('riesgo_intermedio'),
              t('riesgo_bajo'),
              t('riesgo_nulo'),
              t('sin_datos'),
            ]}
            colores={[
              palette.muyAlto,
              palette.alto,
              palette.intermedio,
              palette.bajo,
              palette.nulo,
              palette.sin_datos,
            ]}
            datos={graficoCardiovascular.map((riesgo: IObjGraphCardiovascularProps) => [
              riesgo.riesgo_muy_alto,
              riesgo.riesgo_alto,
              riesgo.riesgo_intermedio,
              riesgo.riesgo_bajo,
              riesgo.riesgo_nulo,
              riesgo.sin_datos,
            ])}
            totales={graficoCardiovascular.map(
              (riesgo: IObjGraphCardiovascularProps) => riesgo.totalDatos,
            )}
          />
        );
      case 'audiometria':
        return getGraficoExamen(tablaAudiometrias[0], 'audiometria');
      case 'emp':
        return getGraficoExamen(tablaEMP[0], 'emp');
      case 'habitos':
        return (
          <DoughnutChart
            id="habitos"
            tipos={tablaHabitosRiesgo.map((habito: IObjTableHabitosProps) => habito.tipo)}
            etiquetas={[t('mujer'), t('hombre'), t('ex_consumidor')]}
            colores={[palette.mujer, palette.hombre, palette.nulo]}
            datos={tablaHabitosRiesgo.map((habito: IObjTableHabitosProps) => [
              habito.mujeres,
              habito.hombres,
              habito.exConsumidor,
            ])}
            totales={tablaHabitosRiesgo.map((habito: IObjTableHabitosProps) => habito.totalDatos)}
            callbackDetalle={handleChangeHabito}
          />
        );
      case 'reporte_general':
        return (
          <div className="flex flex-col">
            <div className="shadow rounded-b-md border-solid border border-gray-100 p-4 text-center">
              <h2 className="text-gray-700 font-medium py-2">{t('riesgo_operativo')}</h2>
              <DoughnutChart
                id="riesgo-operativo"
                tipos={['mujer', 'hombre', 'total']}
                etiquetas={[t('con_riesgo'), t('sin_riesgo')]}
                colores={[palette.muyAlto, palette.sin_datos]}
                datos={graficosRiesgoOperativo.map((row) => [row.conRiesgo, row.sinRiesgo])}
                totales={graficosRiesgoOperativo.map((row) => row.totalDatos)}
              />
            </div>
            <div className="shadow rounded-b-md border-solid border border-gray-100 p-4 text-center">
              <h2 className="text-gray-700 font-medium py-2">{t('grado_salud')}</h2>
              <DoughnutChart
                id="grado-salud"
                tipos={['mujer', 'hombre', 'total']}
                etiquetas={[t('grado4'), t('grado3'), t('grado2'), t('grado1'), t('grado0')]}
                colores={[
                  palette.muyAlto,
                  palette.alto,
                  palette.intermedio,
                  palette.bajo,
                  palette.nulo,
                ]}
                datos={graficosGradoSalud.map((row) => [
                  row.grado4,
                  row.grado3,
                  row.grado2,
                  row.grado1,
                  row.grado0,
                ])}
                totales={graficosRiesgoOperativo.map((row) => row.totalDatos)}
              />
            </div>
          </div>
        );
      case 'riesgos_operativos':
        return (
          <>
            <BarChart
              id="riesgos_operativos_total"
              tipos={['causas_riesgos_operativos']}
              etiquetas={tablaRiesgoOperativo.map((row) => row.diagnostico)}
              colores={[palette.alto]}
              datos={[tablaRiesgoOperativo.map((row) => row.riesgoOperativo)]}
              totales={[graficosRiesgoOperativo[2].totalDatos]}
              adjustHeight
              autoMax
            />
            <BarChart
              id="riesgos_operativos_sexo"
              titulo={t('causas_riesgos_operativos')}
              tipos={['mujer', 'hombre']}
              etiquetas={tablaRiesgoOperativo.map((row) => row.diagnostico)}
              colores={[palette.mujer, palette.hombre]}
              datos={[
                tablaRiesgoOperativo.map((row) => row.mujeres),
                tablaRiesgoOperativo.map((row) => row.hombres),
              ]}
              totales={[
                graficosRiesgoOperativo[0].totalDatos,
                graficosRiesgoOperativo[1].totalDatos,
              ]}
              adjustHeight
              autoMax
              join
            />
          </>
        );
      case 'grados_salud':
        const grados = ['grado4', 'grado3', 'grado2', 'grado1'] as const;
        return grados.map((grado) => {
          const sorted = [...tablaGradoSalud].sort((a, b) =>
            a[grado][0] + a[grado][1] > b[grado][0] + b[grado][1] ? -1 : 1,
          );
          const items = sorted.filter((row) => row[grado][0] + row[grado][1] > 0).length;
          return (
            <BarChart
              id={`grado-salud-${grado}`}
              titulo={t(grado)}
              tipos={['mujer', 'hombre']}
              etiquetas={sorted.map((row) => row.diagnostico)}
              colores={[palette.mujer, palette.hombre]}
              datos={[sorted.map((row) => row[grado][0]), sorted.map((row) => row[grado][1])]}
              totales={[tablaReporteGeneral[0].mujeres, tablaReporteGeneral[0].hombres]}
              adjustHeight={items > 20 ? true : false}
              items={items}
              autoMax
              join
            />
          );
        });
      case 'otros_riesgos':
        return (
          <DoughnutChart
            id="alarma_hacinamiento"
            tipos={['alarma_hacinamiento']}
            etiquetas={[t('mujer'), t('hombre'), t('sin_datos')]}
            colores={[palette.mujer, palette.hombre, palette.sin_datos]}
            datos={[
              [
                tablaHacinamiento[0].mujeres,
                tablaHacinamiento[0].hombres,
                tablaHacinamiento[0].sin_datos,
              ],
            ]}
            totales={[tablaHacinamiento[0].totalDatos]}
          />
        );
      // case 'nom_035_riesgo_general':
      //   return (
      //     <DoughnutChart
      //       tipos={['nom_035_riesgo_general']}
      //       etiquetas={[t('riesgo_muy_alto'), t('riesgo_alto'), t('riesgo_intermedio'), t('riesgo_bajo'), t('riesgo_nulo')]}
      //       colores={[palette.nom_035_muyAlto, palette.nom_035_alto, palette.nom_035_intermedio, palette.nom_035_bajo, palette.nom_035_nulo]}
      //       datos={[[30, 15, 25, 20, 10]]}
      //       totales={[100]}
      //     />
      //   );
      // case 'nom_035_ambiente_trabajo':
      //   return (
      //     <DoughnutChart
      //       tipos={['nom_035_ambiente_trabajo', 'nom_035_condiciones_ambiente']}
      //       etiquetas={[t('riesgo_muy_alto'), t('riesgo_alto'), t('riesgo_intermedio'), t('riesgo_bajo'), t('riesgo_nulo')]}
      //       colores={[palette.nom_035_muyAlto, palette.nom_035_alto, palette.nom_035_intermedio, palette.nom_035_bajo, palette.nom_035_nulo]}
      //       datos={[[15, 8, 40, 21, 16], [12, 18, 20, 35, 15]]}
      //       totales={[100, 100]}
      //     />
      //   );
      // case 'nom_035_factores_actividad':
      //   return (
      //     <DoughnutChart
      //       tipos={['nom_035_factores_actividad', 'nom_035_cargas_trabajo', 'nom_035_falta_de_control']}
      //       etiquetas={[t('riesgo_muy_alto'), t('riesgo_alto'), t('riesgo_intermedio'), t('riesgo_bajo'), t('riesgo_nulo')]}
      //       colores={[palette.nom_035_muyAlto, palette.nom_035_alto, palette.nom_035_intermedio, palette.nom_035_bajo, palette.nom_035_nulo]}
      //       datos={[[6, 12, 18, 32, 32], [13, 5, 16, 46, 20], [8, 3, 17, 34, 38]]}
      //       totales={[100, 100, 100]}
      //     />
      //   );
      // case 'nom_035_tiempo_trabajo':
      //   return (
      //     <DoughnutChart
      //       tipos={['nom_035_organizacion_tiempo', 'nom_035_jornada_trabajo', 'nom_035_interferencia_trabajo']}
      //       etiquetas={[t('riesgo_muy_alto'), t('riesgo_alto'), t('riesgo_intermedio'), t('riesgo_bajo'), t('riesgo_nulo')]}
      //       colores={[palette.nom_035_muyAlto, palette.nom_035_alto, palette.nom_035_intermedio, palette.nom_035_bajo, palette.nom_035_nulo]}
      //       datos={[[5, 15, 23, 28, 29], [18, 8, 3, 15, 56], [21, 11, 44, 4, 20]]}
      //       totales={[100, 100, 100]}
      //     />
      //   );
      // case 'nom_035_liderazgo_relaciones':
      //   return (
      //     <DoughnutChart
      //       tipos={['nom_035_liderazgo_relaciones_', 'nom_035_liderazgo', 'nom_035_relaciones', 'nom_035_violencia']}
      //       etiquetas={[t('riesgo_muy_alto'), t('riesgo_alto'), t('riesgo_intermedio'), t('riesgo_bajo'), t('riesgo_nulo')]}
      //       colores={[palette.nom_035_muyAlto, palette.nom_035_alto, palette.nom_035_intermedio, palette.nom_035_bajo, palette.nom_035_nulo]}
      //       datos={[[16, 4, 13, 41, 26], [2, 21, 32, 14, 31], [19, 7, 35, 12, 27], [22, 3, 45, 18, 12]]}
      //       totales={[100, 100, 100, 100]}
      //     />
      //   );
      // case 'nom_035_entorno_organizacional':
      //   return (
      //     <DoughnutChart
      //       tipos={['nom_035_entorno_organizacional', 'nom_035_reconocimiento', 'nom_035_no_sentido_pertenencia']}
      //       etiquetas={[t('riesgo_muy_alto'), t('riesgo_alto'), t('riesgo_intermedio'), t('riesgo_bajo'), t('riesgo_nulo')]}
      //       colores={[palette.nom_035_muyAlto, palette.nom_035_alto, palette.nom_035_intermedio, palette.nom_035_bajo, palette.nom_035_nulo]}
      //       datos={[[17, 8, 14, 31, 30], [2, 16, 18, 47, 17], [9, 1, 4, 28, 68]]}
      //       totales={[100, 100, 100]}
      //     />
      //   );
      default: {
        setGraphType('table');
        return <></>;
      }
    }
  };

  return <div className="pb-4 text-right">{construirGraficos()}</div>;
};

export default Graficos;
