/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Backdrop, Button, Collapse } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Agenda from '@containers/Agenda/index';
import Pacientes from '@containers/Pacientes/index';
import BarraHerramientas from '@containers/BarraHerramientas';
import TabsPaciente from '@containers/TabsPaciente';
import MedicoInformacion from '@containers/MedicoInformacion';
import AdmonInformacion from '@containers/AdmonInformacion';
import SuperAdmonInformacion from '@containers/SuperAdmonInformacion';
import TableroSuscripciones from '@containers/TableroSuscripciones';
import DashboardPaciente from '@containers/DashboardPaciente';
import { RootState } from 'src/store';
import SnackBarComponente from '@components/SnackBar';
import FormatosMC from '@containers/FormatosMC';
import Login from '@components/Login';
import SignUp from '@components/SignUp';
import Authorize from '@components/Authorize';
import LoggedOutRoute from '@components/LoggedOutRoute';
import LoggedInRoute from '@components/LoggedInRoute';
import { checkAuth } from '@actions/auth/actions';
import LoggedInSubscribedRoute from '@components/LoggedInSubscribedRoute';
import NotFound from '@containers/NotFound';
import Loading from '@components/Loading/Index';
import PersonalizacionFC from '@containers/PersonalizacionFC';
import MiCuenta from '@containers/MiCuenta';
import BusinessDoctor from '@containers/BusinessDoctor';
import { NotificacionesURL } from '@utils/commonUrl';
import OlvidePassword from '@components/OlvidePassword';
import RestablecePassword from '@components/RestablecerPassword';
import { setScreenSize } from '@actions/screenSize/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

function App() {
  // const history = useHistory();
  const classes = useStyles();
  const { t, ready } = useTranslation();
  const loading = useSelector((state: RootState) => state.Loading);
  const { mensaje, severity, open } = useSelector((state: RootState) => state.SnackBar);
  const { acreditado, suscrito } = useSelector((state: RootState) => state.Auth);
  const { rol, esPaciente, idMedico, secciones, customer } = useSelector(
    (state: RootState) => state.Me,
  );
  const { idConsulta } = useSelector((state: RootState) => state.Consulta);
  const [showUpdate, setShowUpdate] = useState<boolean>(false);
  const [showMaintenance, setShowMaintenance] = useState<boolean>(false);
  const [dontShowMaintenance, setDontShowMaintenance] = useState<boolean>(false);
  const [fecha, setFecha] = useState<string>('');
  const dispatch = useDispatch();
  const VERSION = '1.1';

  useEffect(() => {
    const eventSource = new EventSource(NotificacionesURL());
    eventSource.onmessage = (e) => {
      const objTxt = JSON.parse(e.data);
      if (objTxt.lectura && objTxt.actualizado && VERSION !== objTxt.version.toString()) {
        setShowUpdate(true);
      } else if (objTxt.lectura && objTxt.mantenimiento && objTxt.fecha) {
        setShowMaintenance(true);
        setFecha(objTxt.fecha);
      }
    };
    dispatch(checkAuth());
    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    dispatch(setScreenSize(window.innerWidth, window.innerHeight));
    const handleResize = () => dispatch(setScreenSize(window.innerWidth, window.innerHeight));
    window.addEventListener('resize', handleResize);
    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return !ready || suscrito === null || acreditado === null ? (
    <Loading />
  ) : (
    <Router>
      <Collapse in={showUpdate} className="absolute z-40 w-full">
        <Alert
          action={
            <Button color="inherit" size="small" onClick={() => window.location.reload()}>
              {t('actualizar')}
            </Button>
          }
          severity="success"
        >
          <AlertTitle>{t('actualizacion_notificacion')}</AlertTitle>
          {t('actualizacion_texto')}
        </Alert>
      </Collapse>
      <Collapse in={showMaintenance && !dontShowMaintenance} className="absolute z-40 w-full">
        <Alert
          action={
            <Button color="inherit" size="small" onClick={() => setDontShowMaintenance(true)}>
              {t('aceptar')}
            </Button>
          }
          severity="error"
        >
          <AlertTitle>{t('mantenimiento_notificacion')}</AlertTitle>
          {`${t('mantenimiento_texto')} ${fecha}`}
        </Alert>
      </Collapse>
      <div className={classes.root}>
        <SnackBarComponente snackOpen={open} snackSeverity={severity} snackMensaje={mensaje} />
        <Backdrop className={classes.backdrop} open={loading.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="w-full">
          <Switch>
            <LoggedOutRoute path="/login" exact>
              <Login esPaciente={false} />
            </LoggedOutRoute>
            <LoggedOutRoute path="/login-patient" exact>
              <Login esPaciente />
            </LoggedOutRoute>
            <LoggedOutRoute path="/signup" exact>
              <SignUp esPaciente={false} />
            </LoggedOutRoute>
            <LoggedOutRoute path="/signup-patient" exact>
              <SignUp esPaciente />
            </LoggedOutRoute>
            <LoggedOutRoute path="/authorize" exact>
              <Authorize />
            </LoggedOutRoute>
            <LoggedOutRoute path="/password/restablecer/:token">
              <RestablecePassword />
            </LoggedOutRoute>
            <LoggedOutRoute path="/password" exact>
              <OlvidePassword />
            </LoggedOutRoute>
            <LoggedInRoute path="/subscripcion">
              <MiCuenta />
            </LoggedInRoute>
            <LoggedInSubscribedRoute path="/mi-cuenta">
              <div className="container mx-auto px-4">
                <MiCuenta />
              </div>
            </LoggedInSubscribedRoute>
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 1) && (
              <LoggedInSubscribedRoute path="/perfil-medico" exact>
                <MedicoInformacion />
              </LoggedInSubscribedRoute>
            )}
            {secciones.length > 0 &&
              secciones.find((valor) => valor.idSeccion >= 2 && valor.idSeccion <= 6) && (
                <LoggedInSubscribedRoute path="/agenda" exact>
                  <Agenda />
                </LoggedInSubscribedRoute>
              )}
            {secciones.length > 0 &&
              secciones.find((valor) => valor.idSeccion >= 7 && valor.idSeccion <= 56) && (
                <LoggedInSubscribedRoute path="/ece">
                  {idConsulta || esPaciente ? (
                    <>
                      {!esPaciente && (
                        <>
                          <BarraHerramientas />
                          <div className="h-20" />
                        </>
                      )}
                      <TabsPaciente />
                    </>
                  ) : (
                    <Redirect to={{ pathname: '/pacientes' }} />
                  )}
                </LoggedInSubscribedRoute>
              )}
            {secciones.length > 0 &&
              secciones.find((valor) => valor.idSeccion >= 64 && valor.idSeccion <= 66) && (
                <LoggedInSubscribedRoute path="/pacientes" exact>
                  <Pacientes />
                </LoggedInSubscribedRoute>
              )}
            {secciones.length > 0 &&
              secciones.find(
                (valor) =>
                  valor.idSeccion === 61 || (valor.idSeccion >= 67 && valor.idSeccion <= 69),
              ) && (
                <LoggedInSubscribedRoute path="/formatos-consulta" exact>
                  <FormatosMC />
                </LoggedInSubscribedRoute>
              )}
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion === 69) && (
              <LoggedInSubscribedRoute path="/personalizacion-formatos-consulta" exact>
                <PersonalizacionFC />
              </LoggedInSubscribedRoute>
            )}
            {secciones.length > 0 &&
              !esPaciente &&
              secciones.find((valor) => valor.idSeccion === 74) && (
                <LoggedInSubscribedRoute path="/tablero-paciente" exact>
                  <BarraHerramientas />
                  <div style={{ paddingTop: '100px', width: '100%' }} />
                  <DashboardPaciente />
                </LoggedInSubscribedRoute>
              )}
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion <= 149) && (
              <LoggedInSubscribedRoute path="/home" exact>
                <BusinessDoctor />
              </LoggedInSubscribedRoute>
            )}
            {secciones.length > 0 &&
              secciones.find((valor) => valor.idSeccion >= 150 && valor.idSeccion <= 153) && (
                <LoggedInSubscribedRoute path="/administracion" exact>
                  <AdmonInformacion />
                </LoggedInSubscribedRoute>
              )}
            {secciones.length > 0 && secciones.find((valor) => valor.idSeccion >= 154) && (
              <>
                <LoggedInSubscribedRoute path="/home" exact>
                  <TableroSuscripciones />
                </LoggedInSubscribedRoute>
                <LoggedInSubscribedRoute path="/administracion" exact>
                  <SuperAdmonInformacion />
                </LoggedInSubscribedRoute>
              </>
            )}
            <Route path="/" exact>
              {((rol.id !== -1 && idMedico === 0 && customer.length > 0) ||
                rol.grupo === 'salud') && <Redirect to={{ pathname: '/home' }} />}
              {rol.grupo === 'administrativo' &&
                ((rol.tipo === 'superadmin' && <Redirect to={{ pathname: '/home' }} />) ||
                  (rol.tipo !== 'superadmin' && <Redirect to={{ pathname: '/administracion' }} />))}
              {rol.id === -1 && customer.length === 0 && <Redirect to={{ pathname: '/login' }} />}
            </Route>
            <Route path="/">{!acreditado && <Redirect to={{ pathname: '/login' }} />}</Route>
            {idMedico === -1 && <Route path="*" component={NotFound} />}
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
