import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Select, ListSubheader } from '@material-ui/core';
// import useStyles from '../styles';
import { IOperacion, IOperacionesProps, TipoTabla } from './types';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import SelectInput from '@components/FormElemntsLibrary/SelectInput';
import { ChartArea, Table } from 'lucide-react';
import { Button } from '@components/FormElemntsLibrary/Button';
import TablasGenerales from './TablasGenerales';
import TablasDetalles from './TablasDetalles';
import Graficos from './Graficos';
import Detalles from './Detalles';

const Operaciones = (props: IOperacionesProps) => {
  const {
    seleccionOperacion,
    setSeleccionOperacion,
    selectorTabla,
    setSelectorTabla,
    graphType,
    setGraphType,
    verTablaDetalle,
    setVerTablaDetalle,
    plantillaConsultorio,
    plantillaOrganizacion,
    catDistGeo,
    tipoPacientes,
    filtrosRepSalud,
    handleFiltrosSalud,
    tablaCardiovascular,
    tablaAAA,
    tablaCancerPulmon,
    tablaCancerCervical,
    tablaHabitosRiesgo,
    tablaHacinamiento,
    tablaAudiometrias,
    tablaEMP,
    graficoCardiovascular,
    tablaCardiovascularDetalle,
    tablaAAADetalle,
    tablaCancerPulmonDetalle,
    tablaCancerCervicoDetalle,
    tablaAudiometriasDetalle,
    tablaEMPDetalle,
    tableGloborisk,
    setTableGloborisk,
    tablaTabaquismoDetalle,
    tablaCafeinaDetalle,
    tablaAlcoholTrastornoDetalle,
    tablaAlcoholIntensivoDetalle,
    tablaOtrasDrogasDetalle,
    tablaHacinamientoDetalle,
    tablaActividadFisicaDetalle,
    tablaRefrescosDetalle,
    tablaReporteGeneral,
    tablaReporteGeneralDetalle,
    tablaRiesgoOperativo,
    tablaGradoSalud,
    tablaRegistrosFaltantes,
    graficosRiesgoOperativo,
    graficosGradoSalud,
    obtenerDatosOperacionesSalud,
    // arrayCumple,
    // setArrayCumple,
  } = props;
  const { t } = useTranslation();
  // const classes = useStyles();
  const [verDetalle, setVerDetalle] = useState<boolean>(false);
  const [inxSeleccionado, setInxSeleccionado] = useState<number>(0);
  const [selectorHabito, setSelectorHabito] = useState<string>('alarma_tabaquismo');
  // const [selectorOtroRiesgo, setSelectorOtroRiesgo] = useState<string>('tabla_hacinamiento');
  const tablaDetalleRef = useRef<HTMLDivElement>(null);

  /* SELECTOR DE TABLA */
  const handleChangeSelector = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as TipoTabla;
    if (val) {
      setVerDetalle(false);
      setSelectorTabla(val);
    }
  };

  /* OBTENER LOS PORCENTAJES PARA LAS TABLAS */
  const formatTabla = (
    tabla: any,
    formatCols = ['mujeres', 'hombres'],
    formatTotal: boolean = true,
    isArray: boolean = false,
  ) =>
    tabla.map((r: any) => {
      let row = { ...r };
      if (isArray) {
        formatCols.forEach((col) => {
          row[col] = [
            row[col][0] > 0
              ? `${row[col][0]} (${parseFloat(((row[col][0] * 100) / row.totalDatos).toFixed(2))}%)`
              : '0',
            row[col][1] > 0
              ? `${row[col][1]} (${parseFloat(((row[col][1] * 100) / row.totalDatos).toFixed(2))}%)`
              : '0',
          ];
        });
      } else {
        formatCols.forEach((col) => {
          row[col] =
            row[col] > 0
              ? `${row[col]} (${parseFloat(((row[col] * 100) / row.totalDatos).toFixed(2))}%)`
              : '0';
        });
      }
      row.totalAlarma =
        row.totalAlarma > 0 && formatTotal
          ? `${row.totalAlarma} (${parseFloat(
              ((row.totalAlarma * 100) / row.totalDatos).toFixed(2),
            )}%)`
          : row.totalAlarma;
      return row;
    });

  /* DETECTAR SI SE TIENE VALORES EN LA TABLA */
  const conAlarmas =
    (selectorTabla === 'riesgo_cardiovascular' && tablaCardiovascularDetalle.length > 0) ||
    (selectorTabla === 'aaa' && tablaAAADetalle.length > 0) ||
    (selectorTabla === 'cancer_pulmon' && tablaCancerPulmonDetalle.length > 0) ||
    (selectorTabla === 'cancer_cervical' && tablaCancerCervicoDetalle.length > 0) ||
    (selectorTabla === 'habitos' && tablaHabitosRiesgo.length > 0) ||
    (selectorTabla === 'reporte_general' && tablaReporteGeneralDetalle.length > 0) ||
    (selectorTabla === 'riesgos_operativos' && tablaRiesgoOperativo.length > 0) ||
    (selectorTabla === 'grados_salud' && tablaGradoSalud.length > 0) ||
    (selectorTabla === 'registros_faltantes' && tablaRegistrosFaltantes.length > 0) ||
    (selectorTabla === 'otros_riesgos' && tablaHacinamientoDetalle.length > 0) ||
    (selectorTabla === 'audiometria' && tablaAudiometriasDetalle.length > 0) ||
    (selectorTabla === 'emp' && tablaEMPDetalle.length > 0);
  // || selectorTabla === 'nom_035_riesgo_general'
  // || selectorTabla === 'nom_035_ambiente_trabajo'
  // || selectorTabla === 'nom_035_factores_actividad'
  // || selectorTabla === 'nom_035_tiempo_trabajo'
  // || selectorTabla === 'nom_035_liderazgo_relaciones'
  // || selectorTabla === 'nom_035_entorno_organizacional';

  /* SELECTOR DE HÁBITO */
  const handleChangeHabito = (tipo: string) => {
    setVerTablaDetalle(true);
    setSelectorHabito(tipo);
    tablaDetalleRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  // const handleChangeOtroRiesgo = (tipo: string) => {
  //   setSelectorOtroRiesgo(tipo);
  //   otrosRiesgosRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  useEffect(() => {
    if (verTablaDetalle) {
      if (selectorTabla === 'habitos') {
        tablaDetalleRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
      // else if (selectorTabla === 'otros_riesgos') {
      //   otrosRiesgosRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [verTablaDetalle]);

  /* CONFIGURACIÓN DE LAS OPCIONES */
  const operaciones: IOperacion[] = [
    {
      tipo: 'medicina_preventiva',
      tabla: 'riesgo_cardiovascular',
      titulo: 'medicina_preventiva',
      categorias: [
        { tipo: 'header', titulo: 'cardiovascular' },
        { tipo: 'item', titulo: 'riesgo_cardiovascular' },
        { tipo: 'item', titulo: 'aaa' },
        { tipo: 'header', titulo: 'cancer' },
        { tipo: 'item', titulo: 'cancer_pulmon' },
        { tipo: 'item', titulo: 'cancer_cervical' },
      ],
    },
    { tipo: 'habitos', tabla: 'habitos', titulo: 'business_habitos_riesgo' },
    // { tipo: 'riegos_laborales', tabla: 'riegos_laborales', titulo: 'business_riesgos_laborales' },
    {
      tipo: 'operaciones_salud',
      tabla: 'reporte_general',
      titulo: 'operaciones_salud',
      categorias: [
        { tipo: 'item', titulo: 'reporte_general' },
        { tipo: 'item', titulo: 'riesgos_operativos' },
        { tipo: 'item', titulo: 'grados_salud' },
        { tipo: 'item', titulo: 'registros_faltantes' },
      ],
    },
    { tipo: 'otros_riesgos', tabla: 'otros_riesgos', titulo: 'business_otros_riesgos' },
    {
      tipo: 'emo',
      tabla: 'audiometria',
      titulo: 'business_emo',
      categorias: [
        { tipo: 'item', titulo: 'audiometria' },
        { tipo: 'item', titulo: 'emp' },
      ],
    },
    // {
    //   tipo: 'nom-035',
    //   tabla: 'nom_035_riesgo_general',
    //   titulo: 'nom_035',
    //   categorias: [
    //     { tipo: 'item', titulo: 'nom_035_riesgo_general' },
    //     { tipo: 'item', titulo: 'nom_035_ambiente_trabajo' },
    //     { tipo: 'item', titulo: 'nom_035_factores_actividad' },
    //     { tipo: 'item', titulo: 'nom_035_tiempo_trabajo' },
    //     { tipo: 'item', titulo: 'nom_035_liderazgo_relaciones' },
    //     { tipo: 'item', titulo: 'nom_035_entorno_organizacional' },
    //   ],
    // },
  ];
  const distGeo = ['distGeo1', 'distGeo2', 'distGeo3', 'distGeo4'] as const;

  return (
    <>
      <div className="mt-4 mb-4 bg-blue-200 container mx-auto flex content-center items-center p-4">
        <h3 className="text-blue-500 font-medium m-0 pr-4">{`${t('selecciona')}:`}</h3>
        {operaciones.map((operacion, index) => (
          <div key={`div-${index}`} className="pr-4">
            <Button
              label={t(operacion.titulo)}
              color={seleccionOperacion === operacion.tipo ? 'selected' : 'deselected'}
              onClick={() => {
                setSeleccionOperacion(operacion.tipo);
                setSelectorTabla(operacion.tabla);
                setVerTablaDetalle(operacion.tipo !== 'habitos');
              }}
            />
          </div>
        ))}
      </div>
      {operaciones.map((operacion, index) =>
        seleccionOperacion === operacion.tipo ? (
          <div key={`div-${index}`}>
            {seleccionOperacion === 'operaciones_salud' && (
              <>
                <div className="flex flex-row gap-4">
                  {distGeo.map((geo: (typeof distGeo)[number]) => (
                    <SelectInput
                      label={t(geo)}
                      name={geo}
                      options={catDistGeo[geo]}
                      value={filtrosRepSalud[geo]}
                      setValue={handleFiltrosSalud}
                      isNumeric={false}
                      variant={geo === 'distGeo4' ? 'search' : 'normal'}
                    />
                  ))}
                </div>
                <div className="flex flex-row">
                  <CheckboxGroup
                    name="tipoRol"
                    label={t('area')}
                    options={['todos', 'administrativo', 'operativo'].map((op) => ({
                      value: op,
                      label: t(`area_${op}`),
                    }))}
                    value={filtrosRepSalud.tipoRol}
                    setValue={handleFiltrosSalud}
                    distribution="vertical-flex"
                  />
                  <div className="justify-end w-1/4 pt-2">
                    <Button
                      color="primary"
                      label={t('buscar')}
                      onClick={obtenerDatosOperacionesSalud}
                    />
                  </div>
                </div>
              </>
            )}
            <h1 className="text-blue-800 font-normal m-0 text-center">
              {['habitos', 'otros_riesgos'].includes(operacion.tipo)
                ? t(operacion.titulo)
                : t(selectorTabla)}
            </h1>
            <div className="flex flex-row justify-between items-center w-full py-4">
              <div className="flex flex-row justify-between items-center w-1/3">
                {!verDetalle && conAlarmas && (
                  <div>
                    <h3 className="m-0">
                      {`${t('business_plantilla')}: ${
                        operacion.tipo === 'operaciones_salud'
                          ? plantillaOrganizacion
                          : plantillaConsultorio
                      }`}
                    </h3>
                    <div className="flex flex-row">
                      <Button
                        label={t('grafico')}
                        color={graphType === 'chart' ? 'selected' : 'deselected'}
                        icon={<ChartArea />}
                        onClick={() => setGraphType('chart')}
                        position="first"
                      />
                      <Button
                        label={t('tabla')}
                        color={graphType === 'table' ? 'selected' : 'deselected'}
                        icon={<Table />}
                        onClick={() => setGraphType('table')}
                        position="last"
                      />
                    </div>
                  </div>
                )}
              </div>
              {operacion.categorias && (
                <div className="flex flex-row justify-end items-center w-2/3">
                  <div className="pr-2">
                    <h3 className="text-gray-500 font-normal m-0 text-left pr-2">
                      {`${t('categoria')}:`}
                    </h3>
                  </div>
                  <div className="pr-2 w-1/3">
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        displayEmpty
                        labelId="tipo"
                        id="tipo"
                        value={selectorTabla}
                        onChange={handleChangeSelector}
                      >
                        <MenuItem value="" disabled>
                          <span className="text-gray-300">{t('selecciona')}</span>
                        </MenuItem>
                        {operacion.categorias.map((categoria) =>
                          categoria.tipo === 'header' ? (
                            <ListSubheader>{t(categoria.titulo)}</ListSubheader>
                          ) : (
                            <MenuItem value={categoria.titulo}>{t(categoria.titulo)}</MenuItem>
                          ),
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
            </div>
            {conAlarmas && verDetalle && (
              <Detalles
                selectorTabla={selectorTabla}
                setVerDetalle={setVerDetalle}
                inxSeleccionado={inxSeleccionado}
                tablaCardiovascularDetalle={tablaCardiovascularDetalle}
                tablaAAADetalle={tablaAAADetalle}
                tablaCancerPulmonDetalle={tablaCancerPulmonDetalle}
                tablaCancerCervicoDetalle={tablaCancerCervicoDetalle}
                tablaAudiometriasDetalle={tablaAudiometriasDetalle}
                tablaEMPDetalle={tablaEMPDetalle}
                tableGloborisk={tableGloborisk}
              />
            )}
            {conAlarmas && !verDetalle && (
              <>
                {graphType === 'chart' ? (
                  <Graficos
                    selectorTabla={selectorTabla}
                    tablaHabitosRiesgo={tablaHabitosRiesgo}
                    tablaHacinamiento={tablaHacinamiento}
                    tablaAudiometrias={tablaAudiometrias}
                    tablaEMP={tablaEMP}
                    graficoCardiovascular={graficoCardiovascular}
                    tablaReporteGeneral={tablaReporteGeneral}
                    tablaRiesgoOperativo={tablaRiesgoOperativo}
                    tablaGradoSalud={tablaGradoSalud}
                    graficosRiesgoOperativo={graficosRiesgoOperativo}
                    graficosGradoSalud={graficosGradoSalud}
                    setGraphType={setGraphType}
                    handleChangeHabito={handleChangeHabito}
                  />
                ) : (
                  <TablasGenerales
                    selectorTabla={selectorTabla}
                    tablaCardiovascular={tablaCardiovascular}
                    tablaAAA={tablaAAA}
                    tablaCancerPulmon={tablaCancerPulmon}
                    tablaCancerCervical={tablaCancerCervical}
                    tablaHabitosRiesgo={tablaHabitosRiesgo}
                    tablaHacinamiento={tablaHacinamiento}
                    tablaAudiometrias={tablaAudiometrias}
                    tablaEMP={tablaEMP}
                    tablaReporteGeneral={tablaReporteGeneral}
                    tablaRiesgoOperativo={tablaRiesgoOperativo}
                    tablaGradoSalud={tablaGradoSalud}
                    handleChangeHabito={handleChangeHabito}
                    formatTabla={formatTabla}
                  />
                )}
                {selectorTabla === 'riesgo_cardiovascular' && (
                  <div className="text-center">
                    <span className="text-gray-600 font-normal text-lg">
                      {`*${t('business_calculado_usando_globorisk')}`}
                    </span>
                  </div>
                )}
                {verTablaDetalle && (
                  <TablasDetalles
                    operacion={operacion}
                    seleccionOperacion={seleccionOperacion}
                    selectorTabla={selectorTabla}
                    tipoPacientes={tipoPacientes}
                    filtrosRepSalud={filtrosRepSalud}
                    tablaDetalleRef={tablaDetalleRef}
                    tablaCardiovascularDetalle={tablaCardiovascularDetalle}
                    tablaAAADetalle={tablaAAADetalle}
                    tablaCancerPulmonDetalle={tablaCancerPulmonDetalle}
                    tablaCancerCervicoDetalle={tablaCancerCervicoDetalle}
                    tablaAudiometriasDetalle={tablaAudiometriasDetalle}
                    tablaEMPDetalle={tablaEMPDetalle}
                    tablaTabaquismoDetalle={tablaTabaquismoDetalle}
                    tablaCafeinaDetalle={tablaCafeinaDetalle}
                    tablaAlcoholTrastornoDetalle={tablaAlcoholTrastornoDetalle}
                    tablaAlcoholIntensivoDetalle={tablaAlcoholIntensivoDetalle}
                    tablaOtrasDrogasDetalle={tablaOtrasDrogasDetalle}
                    tablaHacinamientoDetalle={tablaHacinamientoDetalle}
                    tablaActividadFisicaDetalle={tablaActividadFisicaDetalle}
                    tablaRefrescosDetalle={tablaRefrescosDetalle}
                    tablaReporteGeneralDetalle={tablaReporteGeneralDetalle}
                    tablaRegistrosFaltantes={tablaRegistrosFaltantes}
                    selectorHabito={selectorHabito}
                    setTableGloborisk={setTableGloborisk}
                    setInxSeleccionado={setInxSeleccionado}
                    setVerDetalle={setVerDetalle}
                    formatTabla={formatTabla}
                  />
                )}
              </>
            )}
            {!conAlarmas && (
              <div className="pt-4 text-center">
                <h3>{t('sin_alarmas')}</h3>
              </div>
            )}
          </div>
        ) : null,
      )}
      {/* <TablaCumple arrayCumple={arrayCumple} /> */}
    </>
  );
};

export default Operaciones;
