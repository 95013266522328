import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { InfoIcon } from 'lucide-react';
import { TriangleAlertIcon } from 'lucide-react';
import { getPublicKey } from '@utils/getCatalogos';
import { sendSignUp } from '@utils/sendInfo';
import { setLoading } from '@actions/loading/actions';
import {
  docIdLongitudMax,
  validacionDocIdOnChange,
  validacionDocId,
  validacionNombres,
  validacionApellidos,
  validacionIp,
} from '@constants/validaciones';
import { removeUserSession, setKeyBack, setToken, setUser } from '@utils/commonStore';
import { setSnackComplete } from '@actions/snackbar/types';
import { setAuth } from '@actions/auth/actions';
import { setEmailMe } from '@utils/commonStore';
import { str2AB } from '@common/base64Convert';
import { getMeMedico } from '@actions/me/actions';
import { ICatStrEstandar, IPhone } from '@common/types';
import { IRegistro, ISignUpProps, registroInitial } from './types';
import TextInput from '@components/FormElemntsLibrary/TextInput';
import CheckboxGroup from '@components/FormElemntsLibrary/CheckboxGroup';
import CheckboxInput from '@components/FormElemntsLibrary/CheckboxInput';
import DateInput from '@components/FormElemntsLibrary/DateInput';
import { SubmitButton } from '@components/FormElemntsLibrary/SubmitButton';
import { User } from 'lucide-react';
import PhoneInput from '@components/FormElemntsLibrary/PhoneInput';
import EmailInput from '@components/FormElemntsLibrary/EmailInput';
import PasswordInput from '@components/FormElemntsLibrary/PasswordInput';
import CountryInput from '@components/FormElemntsLibrary/SelectCountry';
import Dialog from '@components/ModalDialogs/Dialog';

function str2ab(str: string) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i += 1) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

function SignUp({ esPaciente = false }: ISignUpProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const classes = useStyles();
  const location = useLocation();
  const paises: ICatStrEstandar[] = [
    { id: 'mx', label: 'México' },
    { id: 'uy', label: 'Uruguay' },
  ];
  const getURLParameter = (param: string) => new URLSearchParams(location.search).get(param);
  const [ip, setIp] = useState<string>('');
  const [pais, setPais] = useState<string>(
    paises.find((p) => p.id === getURLParameter('country'))?.id || 'mx',
  );
  const [registro, setRegistro] = useState<IRegistro>(registroInitial);
  const [tokenOrg, setTokenOrg] = useState<string>('');
  const [noPoseeEmail, setNoPoseeEmail] = useState<boolean>(false);
  const [showAvisoTokenOrg, setShowAvisoTokenOrg] = useState<boolean>(false);
  const [showNoDocIdModal, setShowNoDocIdModal] = useState<boolean>(false);
  const [showModalPinMedipraxi, setShowModalPinMedipraxi] = useState<boolean>(false);
  const [showModalRegistroSinEmail, setShowModalRegistroSinEmail] = useState<boolean>(false);
  const [kBack, setKBack] = useState<string>('');
  const [backError, setBackError] = useState<boolean>(false);
  const [leidoAvisoPrivacidad, setLeidoAvisoPrivacidad] = useState<boolean>(false);
  const [emailRegistrado, setEmailRegistrado] = useState<string>('');
  const [celularRegistrado, setCelularRegistrado] = useState<IPhone>({ codigo: '', numero: '' });
  const [noExisteRegistro, setNoExisteRegistro] = useState<string>('');
  const [usuarioRegistrado, setUsuarioRegistrado] = useState<string>('');
  const [errors, setErrors] = useState({});

  const fetchIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org');
      const data = await response.text();
      if (validacionIp(data)) {
        setIp(data);
      }
    } catch (e) {
      /* empty */
    }
  };

  useEffect(() => {
    if (pais !== getURLParameter('country')) {
      history.push(`/signup${esPaciente ? '-patient' : ''}?country=${pais}`);
    }
  }, [pais]);
  useEffect(() => {
    dispatch(setLoading(true));
    fetchIP();
    setTokenOrg(getURLParameter('token') || '');
    const abortController = new AbortController();
    const { signal } = abortController;
    getPublicKey(signal)
      .then((result: string) => setKBack(result))
      .catch(() => setBackError(true))
      .finally(() => dispatch(setLoading(false)));
    return () => abortController.abort();
  }, []);
  useEffect(() => {
    if (tokenOrg && tokenOrg.length > 0) {
      if (!esPaciente) {
        history.push(`/signup-patient?pais=${pais}&token=${tokenOrg}`);
      }
      setShowAvisoTokenOrg(true);
      setRegistro((prev) => ({ ...prev, noRecuerdaDocId: tokenOrg.slice(-2)[0] === 'O' }));
    }
  }, [tokenOrg]);
  useEffect(() => {
    if (registro.noPoseeDocId && registro.noRecuerdaDocId) {
      setRegistro((prev) => ({
        ...prev,
        noRecuerdaDocId: false,
      }));
    }
  }, [registro.noPoseeDocId]);
  useEffect(() => {
    if (registro.noPoseeDocId && registro.noRecuerdaDocId) {
      setRegistro((prev) => ({
        ...prev,
        noPoseeDocId: false,
      }));
    }
  }, [registro.noRecuerdaDocId]);
  useEffect(() => {
    if (noPoseeEmail) {
      setShowModalRegistroSinEmail(true);
    }
  }, [noPoseeEmail]);

  const handleInputChange = (e: { name: string; value: any }) =>
    setRegistro((prevData) => ({ ...prevData, [e.name]: e.value }));

  const handleNoDocId = (e: { name: string; value: any }) => {
    setRegistro((prevData) => ({
      ...prevData,
      [e.name]: e.value,
      docId: '',
      numeroDocumento: '',
    }));
  };

  const handleValidationChange = (e: { name: string; hasError: boolean }) => {
    setErrors((prev) => ({ ...prev, [e.name]: e.hasError }));
  };

  const hasErrors = Object.values(errors).some((error) => error === true);

  const registrarUsuario = async () => {
    // creadas el par de llaves del front (usuario)
    const keyPair = await window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 4096,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256',
      },
      true,
      ['encrypt', 'decrypt'],
    );
    // exportadas a JWK (Json Web Key)
    let publicKeyJwk;
    if (keyPair.publicKey) {
      publicKeyJwk = await window.crypto.subtle.exportKey('jwk', keyPair.publicKey);
    } else {
      dispatch(
        setSnackComplete({
          open: true,
          severity: 'error',
          mensaje: t('error_ocurrio_un_error_intentar_mas_tarde'),
        }),
      );
      return;
    }
    // const privateKeyJwk = await window.crypto.subtle.exportKey('jwk', keyPair.privateKey); // a proposito
    // Headers de llaves PEM
    const pemHeader = '-----BEGIN PUBLIC KEY-----';
    const pemFooter = '-----END PUBLIC KEY-----';
    // Importada la key desde el back
    const pemContents = kBack.substring(pemHeader.length, kBack.length - pemFooter.length);
    const binaryDerString = window.atob(pemContents);
    const binaryDer = str2ab(binaryDerString);
    const pemPublicKey = await window.crypto.subtle.importKey(
      'spki',
      binaryDer,
      { name: 'RSA-OAEP', hash: 'SHA-256' },
      true,
      ['encrypt'],
    );
    const encriptarDatoObligatorio = (dato: string) =>
      window.crypto.subtle.encrypt({ name: 'RSA-OAEP' }, pemPublicKey, str2AB(dato));
    const encriptarDatoOpcional = (dato: string) =>
      dato.length > 0
        ? window.crypto.subtle.encrypt({ name: 'RSA-OAEP' }, pemPublicKey, str2AB(dato))
        : null;
    const emailC = await encriptarDatoOpcional(registro.email);
    const contraseniaC = await encriptarDatoObligatorio(registro.contrasenia);
    const nombreC = await encriptarDatoObligatorio(registro.nombre.trim());
    const primerApellidoC = await encriptarDatoObligatorio(registro.primerApellido.trim());
    const celularC = await encriptarDatoObligatorio(registro.celular.numero);
    const fijoC = await encriptarDatoOpcional(registro.fijo.numero);
    const sexoC = await encriptarDatoObligatorio(registro.sexo);
    const fechaNacDiaC = await encriptarDatoObligatorio(registro.fechaNac.dia);
    const fechaNacMesC = await encriptarDatoObligatorio(registro.fechaNac.mes);
    const fechaNacAnioC = await encriptarDatoObligatorio(registro.fechaNac.anio);
    const docIdC = await encriptarDatoOpcional(registro.docId);
    const numeroDocumentoC = await encriptarDatoOpcional(registro.numeroDocumento);
    const tokenOrgC = await encriptarDatoOpcional(tokenOrg);
    const fechaIngresoDiaC = await encriptarDatoOpcional(registro.fechaIngreso.dia);
    const fechaIngresoMesC = await encriptarDatoOpcional(registro.fechaIngreso.mes);
    const fechaIngresoAnioC = await encriptarDatoOpcional(registro.fechaIngreso.anio);
    const numeroSeguroC = await encriptarDatoOpcional(registro.numeroSeguro);
    const numeroEmpleadoC = await encriptarDatoOpcional(registro.numeroEmpleado);
    const pinPacienteC = await encriptarDatoOpcional(registro.pinPaciente);
    const dataToSend = {
      ip,
      pais,
      esPaciente,
      email: emailC ? arrayBufferToBase64(emailC) : '',
      noPoseeEmail: registro.noPoseeEmail,
      password: arrayBufferToBase64(contraseniaC),
      nombre: arrayBufferToBase64(nombreC),
      primerApellido: arrayBufferToBase64(primerApellidoC),
      ladaCel: registro.celular.codigo,
      celular: arrayBufferToBase64(celularC),
      ladaFijo: registro.fijo.codigo,
      fijo: fijoC ? arrayBufferToBase64(fijoC) : '',
      sexo: arrayBufferToBase64(sexoC),
      fechaNacDia: arrayBufferToBase64(fechaNacDiaC),
      fechaNacMes: arrayBufferToBase64(fechaNacMesC),
      fechaNacAnio: arrayBufferToBase64(fechaNacAnioC),
      docId: docIdC ? arrayBufferToBase64(docIdC) : '',
      noPoseeDocId: registro.noPoseeDocId,
      noRecuerdaDocId: registro.noRecuerdaDocId,
      numeroDocumento: numeroDocumentoC ? arrayBufferToBase64(numeroDocumentoC) : '',
      tokenOrg: tokenOrgC ? arrayBufferToBase64(tokenOrgC) : '',
      fechaIngresoDia: fechaIngresoDiaC ? arrayBufferToBase64(fechaIngresoDiaC) : '',
      fechaIngresoMes: fechaIngresoMesC ? arrayBufferToBase64(fechaIngresoMesC) : '',
      fechaIngresoAnio: fechaIngresoAnioC ? arrayBufferToBase64(fechaIngresoAnioC) : '',
      numeroSeguro: numeroSeguroC ? arrayBufferToBase64(numeroSeguroC) : '',
      numeroEmpleado: numeroEmpleadoC ? arrayBufferToBase64(numeroEmpleadoC) : '',
      pinPaciente: pinPacienteC ? arrayBufferToBase64(pinPacienteC) : '',
      llave: publicKeyJwk,
    };
    dispatch(setLoading(true));
    sendSignUp(dataToSend)
      .then((response) => response.json())
      .then((result) => {
        if (result.code === 200) {
          setToken(result.datos.Bearer);
          setUser(result.datos.userSend);
          setEmailMe(result.datos.userSend);
          setKeyBack(kBack);
          dispatch(
            setAuth({
              id: result.datos.userSend,
              acreditado: true,
              suscrito: esPaciente,
            }),
          );
          history.push(esPaciente ? '/ece' : '/home');
          dispatch(getMeMedico(result.datos.userSend));
        } else {
          let errorMsg = '';
          switch (result.code) {
            case 409:
              errorMsg = t('email_registrado_en_medipraxi');
              setEmailRegistrado(registro.email);
              // newErrores.email = { error: true, helperText: t('email_registrado_en_medipraxi') };
              break;
            case 414:
              errorMsg = t('celular_registrado_en_medipraxi');
              setCelularRegistrado(registro.celular);
              // newErrores.email = { error: true, helperText: t('email_registrado_en_medipraxi') };
              break;
            case 410:
              // Ingresó un código de paciente sin documento de identidad (PACI-XXXX) pero no corresponde a ningún registro
              errorMsg = t('error_no_existe_registro');
              setNoExisteRegistro(registro.docId);
              // newErrores.docId = { error: true, helperText: t('error_no_existe_registro') };
              break;
            case 411:
              // Ya existe un usuario validado con ese documento de identidad o código de Medipraxi
              errorMsg = t('error_usuario_registrado');
              setUsuarioRegistrado(registro.numeroDocumento);
              // newErrores.documento = { error: true, helperText: t('error_usuario_registrado') };
              break;
            case 412:
              errorMsg = t('error_token__org_invalido');
              break;
            case 500:
              errorMsg = t('error_ocurrio_un_error_en_el_servidor');
              break;
            default:
              errorMsg = t('error_ocurrio_un_error_intentar_mas_tarde');
              break;
          }
          dispatch(
            setSnackComplete({
              open: true,
              severity: 'error',
              mensaje: errorMsg,
            }),
          );
          removeUserSession();
          setToken('');
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        dispatch(
          setSnackComplete({
            open: true,
            severity: 'error',
            mensaje: `${t('message-error-warning')} ${err.toString()}`,
          }),
        );
      });
  };

  return backError ? (
    <div className="place-self-center flex flex-row gap-x-2 mx-auto shadow-lg bg-gray-100 rounded-xl mt-16 lg:mt-0 p-6 border border-solid border-gray-200">
      <TriangleAlertIcon color="red" size={20} />
      <span className="text-base">{t('error_back')}</span>
    </div>
  ) : (
    <>
      <div className="absolute float-right right-0 top-2 w-20">
        <CountryInput name="pais" label="" value={pais} setValue={(e) => setPais(e.value)} />
        {/* <CheckboxGroup
          name="tipo"
          options={[
            { value: 'salud', label: 'Soy profesional de salud' },
            { value: 'paciente', label: 'Soy paciente' },
          ]}
          value={registro.sexo}
          setValue={handleInputChange}
          distribution="vertical-flex"
          isRequired
        /> */}
        {/* <SelectInput
          name="pais"
          label=""
          options={paises}
          value={pais}
          setValue={(e) => setPais(e.value)}
        /> */}
        {/* <FormControl>
          <Select
            displayEmpty
            labelId="pais"
            id="pais"
            value={pais}
            onChange={(e) => setPais(e.target.value as string)}
            inputProps={{
              name: 'Pais',
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 375,
                },
              },
            }}
            renderValue={() => (
              <Box sx={{ display: 'flex' }}>
                <SvgIcon className="mr-2">
                  {pais === 'mx' && <FlagMx />}
                  {pais === 'uy' && <FlagUy />}
                </SvgIcon>
              </Box>
            )}
          >
            {paises.map((p, inx) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem value={p.id} key={inx}>
                {p.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </div>
      <div className="absolute w-full inset-y-16">
        <div className="relative md:mx-auto md:w-8/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12">
          <div className="absolute w-full shadow-lg bg-white rounded-xl mt-16 lg:mt-0 p-6 border border-solid border-gray-200">
            {/* <div
              className="absolute right-4 cursor-pointer"
              onClick={() => {
                history.push(esPaciente ? '/login-patient' : '/login');
              }}
            >
              <XIcon size={20} className="text-gray-600" />
            </div> */}
            <div className="w-full text-center">
              <h2 className="text-gray-900 font-normal mb-0">{t('registro_de_usuarios')}</h2>
            </div>
            <div className="pt-4 pb-2 my-2">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-4">
                <TextInput
                  name="nombre"
                  floatingLabel={t('nombre(s)')}
                  value={registro.nombre}
                  setValue={handleInputChange}
                  validacion={(val) =>
                    val.length && !validacionNombres(val) ? t('nombre_incorrecto') : ''
                  }
                  fixedValue={<User />}
                  maxLength={50}
                  isRequired
                  onValidationChange={handleValidationChange}
                />
                <TextInput
                  name="primerApellido"
                  floatingLabel={t('primer_apellido')}
                  value={registro.primerApellido}
                  setValue={handleInputChange}
                  validacion={(val) =>
                    val.length && !validacionApellidos(val) ? t('primer_apellido_incorrecto') : ''
                  }
                  fixedValue={<User />}
                  maxLength={50}
                  isRequired
                  onValidationChange={handleValidationChange}
                />
                <PhoneInput
                  name="celular"
                  floatingLabel={t('telefono_celular')}
                  value={registro.celular}
                  setValue={handleInputChange}
                  isRequired
                  onValidationChange={handleValidationChange}
                  errorMessage={
                    registro.noPoseeEmail &&
                    celularRegistrado.numero.length > 0 &&
                    registro.celular === celularRegistrado
                      ? t('email_registrado_en_medipraxi')
                      : undefined
                  }
                />
                <PhoneInput
                  name="fijo"
                  floatingLabel={t('telefono_fijo')}
                  value={registro.fijo}
                  setValue={handleInputChange}
                  onValidationChange={handleValidationChange}
                />
                <CheckboxGroup
                  name="sexo"
                  options={[
                    { value: 'H', label: t('hombre') },
                    { value: 'M', label: t('mujer') },
                  ]}
                  value={registro.sexo}
                  setValue={handleInputChange}
                  distribution="vertical-flex"
                  isRequired
                />
                <DateInput
                  label={t('fecha_nacimiento')}
                  name="fechaNac"
                  value={registro.fechaNac}
                  setValue={handleInputChange}
                  untilToday
                  isRequired
                  onValidationChange={handleValidationChange}
                />
                {tokenOrg.length > 0 && (
                  <>
                    <TextInput
                      name="numeroEmpleado"
                      floatingLabel={t('numero_empleado')}
                      value={registro.numeroEmpleado}
                      setValue={handleInputChange}
                      maxLength={50}
                      isRequired
                      onValidationChange={handleValidationChange}
                      // isRequired={registro.noRecuerdaDocId}
                    />
                    <div />
                  </>
                )}
                {(tokenOrg.length < 2 || tokenOrg.slice(-2)[0] !== 'O') && (
                  <>
                    <TextInput
                      containerClass={`${registro.noPoseeDocId ? '' : 'hidden'}`}
                      name="numeroDocumento"
                      floatingLabel={t('numero_documento')}
                      value={registro.numeroDocumento}
                      setValue={handleInputChange}
                      errorMessage={
                        noExisteRegistro.length > 0 && registro.numeroDocumento === noExisteRegistro
                          ? t('error_no_existe_registro')
                          : undefined
                      }
                      maxLength={50}
                      isRequired={registro.noPoseeDocId}
                      onValidationChange={handleValidationChange}
                    />
                    <TextInput
                      containerClass={`${registro.noPoseeDocId ? 'hidden' : ''}`}
                      name="docId"
                      floatingLabel={t(`doc_id_${pais}`)}
                      value={registro.docId}
                      textCase="upper"
                      maxLength={docIdLongitudMax(pais)}
                      setValue={handleInputChange}
                      validacion={(val) =>
                        val.length > 0 && !validacionDocIdOnChange(val, pais)
                          ? t(`doc_id_incorrecto_${pais}`)
                          : ''
                      }
                      validacionBlur={(val) =>
                        val.length > 0 && !validacionDocId(val, pais)
                          ? t(`doc_id_incorrecto_${pais}`)
                          : ''
                      }
                      errorMessage={
                        usuarioRegistrado.length > 0 && registro.docId === usuarioRegistrado
                          ? t('error_usuario_registrado')
                          : undefined
                      }
                      disabled={registro.noRecuerdaDocId}
                      isRequired={!registro.noPoseeDocId && !registro.noRecuerdaDocId}
                      onValidationChange={handleValidationChange}
                    />
                    <div className="flex flex-row md:flex-col px-4 gap-x-4">
                      {tokenOrg.length > 0 && (
                        <CheckboxInput
                          containerClass="mb-[5px] md:mt-[10px]"
                          name="noRecuerdaDocId"
                          label={t(`no_recuerdo_doc_id_${pais}`)}
                          value={registro.noRecuerdaDocId}
                          setValue={handleNoDocId}
                        />
                      )}
                      <CheckboxInput
                        containerClass={tokenOrg.length > 0 ? 'mt-[0px]' : 'mt-[25px]'}
                        name="noPoseeDocId"
                        label={t(`no_poseo_doc_id_${pais}`)}
                        value={registro.noPoseeDocId}
                        setValue={handleNoDocId}
                      />
                    </div>
                  </>
                )}
                {/* {tokenOrg.length > 0 && tokenOrg.slice(-1) === 'I' &&
                  <>
                    <DateInput
                      name="fechaIngreso"
                      label={t('fecha_de_ingreso')}
                      value={registro.fechaIngreso}
                      setValue={handleInputChange}
                      untilToday
                      isRequired
                    />
                    <TextInput
                      name="numeroSeguro"
                      floatingLabel={t('numero_seguro_social')}
                      value={registro.numeroSeguro}
                      setValue={handleInputChange}
                      maxLength={50}
                      isRequired
                    />
                  </>
                } */}
                {esPaciente && (
                  <CheckboxInput
                    containerClass="md:col-span-2 text-right mb-[0px]"
                    name="noPoseeEmail"
                    label={t('no_poseo_email')}
                    value={noPoseeEmail}
                    setValue={(e) => {
                      setNoPoseeEmail(e.value);
                      if (!e.value) {
                        setRegistro((prev) => ({ ...prev, noPoseeEmail: false }));
                      }
                    }}
                  />
                )}
                <EmailInput
                  containerClass="md:col-span-2 mt-[0px]"
                  floatingLabel={t('correo_electronico')}
                  name="email"
                  value={registro.email}
                  setValue={handleInputChange}
                  errorMessage={
                    emailRegistrado.length > 0 && registro.email === emailRegistrado
                      ? t('email_registrado_en_medipraxi')
                      : undefined
                  }
                  // disableOption={{
                  //   label: t('no_poseo_email'),
                  //   name: 'noPoseeEmail',
                  //   value: registro.noPoseeEmail,
                  //   position: 'right',
                  // }}
                  disableCopy
                  disableCut
                  disablePaste
                  showIcon
                  isRequired={!registro.noPoseeEmail}
                  disabled={registro.noPoseeEmail}
                  onValidationChange={handleValidationChange}
                />
                <EmailInput
                  containerClass="md:col-span-2"
                  floatingLabel={t('repetir_correo_electronico')}
                  name="emailRepetido"
                  value={registro.emailRepetido}
                  setValue={handleInputChange}
                  validacionBlur={(val) =>
                    val !== registro.email ? t('email_incorrecto_repetir') : ''
                  }
                  disableCopy
                  disableCut
                  disablePaste
                  showIcon
                  isRequired={!registro.noPoseeEmail}
                  disabled={registro.noPoseeEmail}
                  onValidationChange={handleValidationChange}
                />
                <PasswordInput
                  name="contrasenia"
                  floatingLabel={t('contrasenia')}
                  value={registro.contrasenia}
                  setValue={handleInputChange}
                  isRequired
                  onValidationChange={handleValidationChange}
                />
                <PasswordInput
                  name="contraseniaRepetida"
                  floatingLabel={t('repetir_contrasenia')}
                  value={registro.contraseniaRepetida}
                  setValue={handleInputChange}
                  validacionBlur={(val) =>
                    val !== registro.contrasenia ? t('las_contrasenias_no_coinciden') : ''
                  }
                  isRequired
                  // onValidationChange={handleValidationChange}
                  requirements={[]}
                />
              </div>
            </div>
            <CheckboxInput
              containerClass="pl-2"
              name="avisoPrivacidad"
              label={
                <span>
                  <span>{`${t('he_leido_el')} `}</span>
                  <a
                    href={`${
                      esPaciente
                        ? 'https://medipraxi.com/aviso-de-privacidad/'
                        : 'https://medipraxi.com/privacidad-medicos/'
                    }`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-700 underline"
                  >
                    {t('aviso_de_privacidad')}
                  </a>
                </span>
              }
              value={leidoAvisoPrivacidad}
              setValue={() => setLeidoAvisoPrivacidad((prev) => !prev)}
            />
            {esPaciente && !tokenOrg.length && (
              <div className="flex flex-row mt-2 justify-end">
                <button
                  className="text-sm mr-2 text-blue-600"
                  onClick={() => setShowModalPinMedipraxi(true)}
                >
                  {t('tengo_pin_medipraxi')}
                </button>
                <div data-tooltip={t('tengo_pin_medipraxi_descripcion')}>
                  <InfoIcon size={15} color="#2563EB" />
                </div>
              </div>
            )}
            <SubmitButton
              label={esPaciente ? t('guardar') : t('continuar')}
              saveCallback={registrarUsuario}
              disabled={
                (!registro.noPoseeEmail && registro.email !== registro.emailRepetido) ||
                registro.contrasenia !== registro.contraseniaRepetida ||
                hasErrors ||
                !leidoAvisoPrivacidad
              }
            />
            <div className="text-center mt-6">
              <button
                className="text-base text-blue-700"
                onClick={() => history.push(esPaciente ? '/login-patient' : '/login')}
              >
                {t('ya_estoy_registrado')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        type="options"
        open={showNoDocIdModal}
        onClose={(anwser) => {
          setShowNoDocIdModal(false);
          setRegistro((prev) => ({ ...prev, noPoseeDocId: !anwser }));
        }}
        title={t(`registrar_usuario_no_doc_id_${pais}`)}
        acceptButtonText={t(`si_poseo_doc_id_${pais}`)}
        cancelButtonText={t(`no_poseo_doc_id_${pais}`)}
        maxWidth="md"
        alert
      >
        <p className="text-justify text-red-500 m-0 my-2">{t(`registro_no_doc_id_l1_${pais}`)}</p>
        <p className="text-justify text-red-500 m-0 my-2">
          <Trans i18nKey="registro_no_doc_id_l2">
            index0
            <strong title="index1">index1</strong>
            index2
          </Trans>
        </p>
        <p className="text-justify text-gray-500 m-0 mt-2">
          <Trans i18nKey={`registro_no_doc_id_l3_${pais}`}>
            index0
            <strong title="index1">index1</strong>
            index2
          </Trans>
        </p>
      </Dialog>
      <Dialog
        open={showModalPinMedipraxi}
        onClose={() => setShowModalPinMedipraxi(false)}
        title={t('ingresar_pin_medipraxi')}
      >
        <PasswordInput
          name="pinPaciente"
          floatingLabel={t('pin_medipraxi')}
          value={registro.pinPaciente}
          setValue={handleInputChange}
          requirements={[]}
          numeric
        />
      </Dialog>
      <Dialog
        open={showAvisoTokenOrg && kBack.length > 0}
        onClose={() => setShowAvisoTokenOrg(false)}
        title={t('aviso').toUpperCase()}
        text={t('aviso_token_organizacion_texto')}
      />
      <Dialog
        type="options"
        open={showModalRegistroSinEmail}
        onClose={(anwser) => {
          setShowModalRegistroSinEmail(false);
          setNoPoseeEmail(!anwser);
          if (anwser) {
            setRegistro((prev) => ({ ...prev, noPoseeEmail: false }));
          } else {
            setRegistro((prev) => ({
              ...prev,
              email: '',
              emailRepetido: '',
              noPoseeEmail: true,
            }));
          }
        }}
        title={t('advertencia')}
        text={`${t('no_poseo_email_modal_advertencia')} de Oxxo.`}
        acceptButtonText={t('si_poseo_correo')}
        cancelButtonText={t('no_poseo_correo')}
        alert
      />
    </>
  );
}

export default SignUp;
